import React from "react";
import { Link } from "gatsby";
import { Container } from "reactstrap";

import Layout from "../components/layout";
import Contact from "../components/contact";

const SecondPage = () => (
  <Layout title="Prop Maker - Contact Us">
    <Container>
      <div className="pt-5" />
      <Contact />
    </Container>
  </Layout>
);

export default SecondPage;
